// @flow
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { appConfiguration } from '../AppConfiguration';
import ChatMessageStyles from './Styles/ChatMessageStyles';

const SplashScreen = () => (
    <div style={ChatMessageStyles.grayText}>
        <Row>
            <Col xs style={ChatMessageStyles.selectChatroom}>
                <span>Select a room to view and send messages</span>
            </Col>
        </Row>
        <Row>
            <Col xs style={ChatMessageStyles.whatsNewCol}>
                <h3 style={ChatMessageStyles.whatsNewHeader}>
                    {`What’s new in OmniLife Web v${appConfiguration().version}`}
                </h3>
                <h4>
                    Major Changes
                </h4>
                <ul>
                    <li>
                        Rooms list redesigned for improved readability of room and group names
                    </li>
                </ul>
                <h4>
                    Minor Changes
                </h4>
                <ul>
                    <li>
                        Adds the ability to clear all fields within a case task
                    </li>
                    <li>
                        Simpler navigation to case from case room
                    </li>
                    <li>
                        More consistent sorting of users by last name
                    </li>
                    <li>
                        Fixes password reset bug
                    </li>
                    <li>
                        Additional case tags are available (10 new tags added)
                    </li>
                </ul>
            </Col>
        </Row>
    </div>
);

export default SplashScreen;
