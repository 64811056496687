// @flow

type AppConfiguration = {
    version: string,
};

export const appConfiguration = (): AppConfiguration => ({
    version: '2.65.1',
});

export default appConfiguration;
